$primary-dark-color: #121212;
$secondary-dark-color: #1e1e1e;
$primary-text-dark-color: #e0e0e0;
$secondary-text-dark-color: #a0a0a0;

$tertiary-dark-color: #333;

$primary-border-color: #333;
$secondary-border-color: #1e1e1e;

$primary-form-input-background: #2c2c2c;
$primary-form-input-border-color: #2c2c2c;

.dark-theme {
  body {
    background: $primary-dark-color;
  }

  .ant-layout {
    background: $primary-dark-color;
  }

  .app-header {
    background: $secondary-dark-color;
    border-bottom-color: #333333;

    .btn-menu-toggle {
      color: $primary-text-dark-color;
    }

    .org-name {
      color: $primary-text-dark-color;
    }

    .app-login-user {
      .label-login-user-name a {
        color: $primary-text-dark-color;
      }
    }
  }

  .dashboard-wrapper {
    .col-left {
      background: $primary-dark-color;
    }

    .col-right {
      background: $secondary-dark-color;
      border-left-color: #333333;
    }
  }

  .ant-layout-content {
    background: $secondary-dark-color;
  }

  .ant-form {
    .ant-form-item {
      label {
        color: $primary-text-dark-color;
      }
    }
  }

  aside.ant-layout-sider.tp_sidebar_main {
    background: $secondary-dark-color !important;
  }

  .ant-menu {
    background: $secondary-dark-color;

    &:not(.ant-menu-horizontal) .ant-menu-item-selected {
      background: #2c2c2c;

      .menu-item-label {
        color: #e6e6e6;
      }
    }
  }

  .ant-layout-sider-children {
    border-right: solid 1px $primary-border-color;
  }

  .ant-menu-inline,
  .ant-menu-vertical,
  .ant-menu-vertical-left {
    border: none;
  }

  // dashboard
  .dashboard-card-t1 {
    background-color: $secondary-dark-color;
    border-color: $secondary-border-color;

    h1 {
      color: $primary-text-dark-color;
    }

    h3 {
      color: $primary-text-dark-color;
    }

    .ico-box-rounded {
      background: $tertiary-dark-color !important;
    }
  }

  .dashboard-side-chart {
    h3 {
      color: $primary-text-dark-color;
    }

    h5 {
      color: $primary-text-dark-color;
    }
  }

  .db-tabs-buttons-wrapper-t2 .activateLink {
    background: #272727;
    color: #36cfc9;
  }

  .content-header {
    h2 {
      color: $primary-text-dark-color;
    }
  }

  // forms

  .ant-input-affix-wrapper {
    border-color: $primary-form-input-border-color;
    background: $primary-form-input-background;

    &:focus,
    &:active,
    &:hover {
      box-shadow: 0 0 0 2px $primary-dark-color !important;
    }

    input,
    input[type='text'],
    input[type='password'] {
      background: $primary-form-input-background;
      color: $secondary-text-dark-color;

      &:focus,
      &:active,
      &:hover {
        box-shadow: none !important;
      }
    }
  }

  .ant-input,
  .ant-input-number {
    border-color: $primary-form-input-border-color;
    background: $primary-form-input-background;
    color: $secondary-text-dark-color;

    &:focus,
    &:active,
    &:hover {
      box-shadow: 0 0 0 2px $primary-dark-color !important;
    }
  }

  .ant-picker {
    border-color: $primary-form-input-border-color;
    background: $primary-form-input-background;

    &:focus,
    &:active,
    &:hover {
      box-shadow: 0 0 0 2px $primary-dark-color !important;
    }

    input,
    input[type='text'],
    input[type='password'],
    .base-input {
      background: $primary-form-input-background;
      color: $secondary-text-dark-color;
    }
  }

  .ant-select.ant-select-single {
    .ant-select-selection-item {
      color: $primary-text-dark-color;
    }

    div.ant-select-selector {
      border: solid 1px $primary-form-input-border-color !important;
      background: $primary-form-input-background;
      color: $secondary-text-dark-color;
    }

    &:focus,
    &:active,
    &:hover {
      box-shadow: 0 0 0 2px $primary-dark-color !important;
    }
  }

  .ant-radio-group-large {
    .ant-radio-button-wrapper {
      border: 1px solid $primary-form-input-border-color !important;
      background: $primary-form-input-background;
      color: #888997;

      &:focus,
      &:active {
        //box-shadow: 0 0 0 2px $primary-dark-color !important
      }

      &:first-child {
        border-left: 1px solid $primary-form-input-border-color !important;
      }
    }
  }

  .ant-radio-group-solid
    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    background: $niagara;
    border-color: $niagara;
    color: $primary-text-dark-color;
  }

  // table
  .ant-table {
    table {
      .ant-table-thead > {
        tr > th.ant-table-cell {
          background: #272727;
          border-bottom: 1px solid $primary-border-color;
          color: $primary-text-dark-color;
        }
      }

      .ant-table-tbody > {
        tr > td.ant-table-cell {
          background: $secondary-dark-color;
          border-bottom: 1px solid $primary-border-color;
          color: $primary-text-dark-color;
        }
      }
    }
  }

  .ant-switch {
    background: #2c2c2c;

    &.ant-switch-checked {
      .ant-switch-handle::before {
        background: $primary;
      }
    }
  }

  // paginations

  .ant-pagination {
    .ant-pagination-prev .ant-pagination-item-link,
    .ant-pagination-next .ant-pagination-item-link,
    .ant-pagination-item,
    .ant-pagination-jump-prev .ant-pagination-item-link,
    .ant-pagination-jump-next .ant-pagination-item-link {
      background: transparent;
      color: $secondary-text-dark-color;
      border: none;

      a {
        color: $secondary-text-dark-color;
      }

      .ant-pagination-item-ellipsis {
        color: $secondary-text-dark-color;
      }

      &.ant-pagination-item-active a {
        color: $primary-dark-color;
      }
    }
  }

  .ant-collapse {
    background-color: $primary-form-input-background;
    border-color: $secondary-border-color;

    > .ant-collapse-item {
      border-bottom-color: $secondary-border-color;

      > .ant-collapse-header {
        color: $primary-text-dark-color;
      }
    }

    .ant-collapse-content {
      background: $primary-dark-color;
      border-color: $secondary-border-color;
    }
  }

  .right-score-card-wrapper {
    h1 {
      color: $primary-text-dark-color;
    }

    h5 {
      color: $secondary-text-dark-color;
    }
  }

  .upload-wrapper {
    background-color: $secondary-dark-color;
  }

  .pie-font {
    .pie-label {
      color: $primary-text-dark-color;
    }

    .pie-numbers {
      color: $secondary-text-dark-color;
    }
  }
}
