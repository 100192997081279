@import '../../assets/scss/variables/_variable.scss';

.ant-form {
  font-size: em(16);

  .ant-form-item {
    font-size: em(16);

    .ant-form-item-label,
    label,
    .ant-input-affix-wrapper {
      font-size: rem(14);
    }

    .ant-form-item-label {
      padding-bottom: em(2) !important;
    }

    label {
      color: $manatee;
      font-weight: 500;
    }

    .ant-input-textarea {
      textarea {
        padding-top: rem(8);
        padding-bottom: rem(8);
      }
    }
  }
}

.ant-btn-link {
  color: $primary;
}

.ant-btn {
  font-family: $font-family-sans-serif;
}

// Table
.ant-table {
  table {
    font-size: rem(13);
    font-family: $font-family-sans-serif;
    color: $scorpion;

    .ant-table-thead > tr > th {
      &.ant-table-cell {
        background: $white;
        font-weight: 600;
        color: $scorpion;
      }
    }

    td {
      font-size: rem(13);
      color: $scorpion;
      font-weight: 500;

      .ant-btn.ant-dropdown-trigger {
        margin: 0;
      }

      .icon-row-view {
        font-size: rem(18);

        &:hover::before {
          color: $primary;
        }
      }

      .anticon-delete,
      .anticon-history {
        font-size: rem(16);
      }
    }

    .ant-table-tbody > tr > td.ant-table-cell {
      padding-top: rem(12);
      padding-bottom: rem(12);
    }
  }
}

.ant-table-wrapper.table-t2.table-form-inline {
  .ant-table-content {
    .ant-form-item {
      margin: 0;

      .ant-input,
      .ant-input-number {
        border: 1px solid transparent;
        border-radius: 0;
        padding-left: rem(8);
        padding-right: rem(8);
        background: transparent;

        &:focus,
        &:active,
        &:hover {
          border: 1px solid $primary;
          box-shadow: none;
          outline: none;
        }

        .ant-input-number-input {
          border: 0;
          padding-left: 0;
          padding-right: 0;
          background: transparent;

          .ant-input-number-input-wrap {
            &:focus,
            &:active,
            &:hover {
              border: 0;
              box-shadow: none;
              outline: none;
            }
          }
        }
      }
    }

    .ant-table-tbody > tr > td.ant-table-cell {
      padding: 0 !important;

      .btn-delete {
        margin: 0 7px 0 12px;
      }
    }
  }

  .discrepancies-cell-wrapper {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;

    .ant-form-item {
      width: 90%;
    }

    .icon-warning {
      margin-right: em(8);
    }

    .ant-input-group-addon {
      border: 0;
    }
  }
}

.ant-pagination-total-text {
  position: absolute;
  left: 0;
  color: $manatee;
  font-size: rem(13);
  line-height: rem(35);
}

.ant-pagination-options {
  position: absolute;
  right: 0;

  .ant-select.ant-select-single {
    .ant-select-selection-item,
    .ant-select-selection-search,
    .ant-select-selection-placeholder {
      line-height: rem(30);
    }

    div.ant-select-selector {
      height: rem(30);
    }
  }
}

.ant-table-wrapper.table-t2 {
  .ant-table-thead > tr > th {
    &.ant-table-cell {
      border: solid 1px $gallery;
      border-right: 0;

      &:last-child {
        border-right: solid 1px $gallery;
      }
    }
  }

  .ant-table-tbody > tr > td {
    &.ant-table-cell {
      border: solid 1px $gallery;
      border-right: 0;

      &:last-child {
        border-right: solid 1px $gallery;
      }

      p {
        margin: 0;
      }

      .icon-warning {
        font-size: rem(16);
        line-height: rem(20);
      }
    }
  }

  .ant-table-summary > tr > td {
    &.ant-table-cell {
      padding-top: 12px;
      border: none;
    }
  }

  &.table-discrepancies {
    .ant-table-row-expand-icon-cell {
      width: 0px !important;
      padding: 0;
      margin: 0;
    }

    .ant-table-tbody {
      .ant-table-expanded-row {
        .ant-table-cell {
          padding: 0 !important;
        }
      }
    }

    .ant-table-cell.remove-left-border {
      border-left: 0 !important;
    }
  }

  &.table-add-po-sp {
    thead.ant-table-thead {
      tr {
        th:last-child {
          width: 0 !important;
          padding: 0;
          margin: 0;
          border: 0;
          border-left: solid 1px #ebebeb;
          display: none;
        }
      }
    }

    tbody.ant-table-tbody {
      tr {
        td:last-child {
          width: 0px !important;
          padding: 0;
          margin: 0;
          border: 0;
          border-left: solid 1px #ebebeb;
          display: none;
        }
      }
    }

    .ant-table-tbody {
      .ant-table-expanded-row {
        .ant-table-cell {
          padding: 0 !important;
        }
      }
    }

    .ant-table-cell.remove-left-border {
      border-left: 0 !important;
    }
  }

  .ant-input-group-addon {
    border: 0;
  }
}

.ant-tooltip.table-tooltip {
  max-width: em(350);

  .ant-tooltip-arrow-content {
    height: em(10);
    width: em(10);
  }

  .ant-tooltip-inner {
    border-radius: em(8);
    font-size: rem(13);
    min-height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: em(12);
    padding-right: em(12);
  }
}

// ----------- Forms

// Radio
.ant-radio {
  &.ant-radio-checked {
    .ant-radio-inner {
      border-color: $primary;

      &::after {
        background-color: $primary;
      }
    }
  }
}

.ant-form-item-control-input {
  min-height: em(40);

  .ant-input-textarea.ant-input-textarea-show-count {
    &::after {
      font-size: em(12);
    }
  }
}

.ant-radio-wrapper {
  margin-right: em(38);
}

.ant-input,
.ant-input-number {
  border-radius: em($base-border-radius);
  border: solid 1px $clr-input-border;
  background-color: $clr-input-bg;
  line-height: rem(42);
  color: $scorpion;
  font-size: rem(14);
  height: rem(40);
  padding: 0 rem(18);
  width: 100%;

  &:focus,
  &:active,
  &:hover {
    border-color: $primary;
    -webkit-box-shadow: 0 0 0 2px lighten($niagara, 35%);
    box-shadow: 0 0 0 2px lighten($niagara, 35%);
  }

  &::placeholder {
    font-size: em(14);
    color: $manatee;
  }
}

// Button
.btn-primary {
  background: $primary;
  border-color: $primary;
  color: $white;
  font-size: rem(13);
  border-radius: em($btn-border-radius);
  font-weight: 400;
  height: rem(40);
  min-width: rem(140);
  line-height: rem(35);

  &:hover,
  &:active,
  &:focus {
    background-color: $clr-link-hover;
    border-color: $clr-link-hover;
    color: $white;
  }

  .btn-icon {
    margin-right: rem(8);

    &::before {
      color: $white;
    }
  }

  &.btn-t2 {
    background: $charcoal-grey;
    border-color: $charcoal-grey;

    &:hover,
    &:active,
    &:focus {
      background-color: $black;
      border-color: $black;
      color: $white;
    }
  }

  &.btn-t3 {
    background: $coral;
    border-color: $coral;

    &:hover,
    &:active,
    &:focus {
      background-color: $bittersweet;
      border-color: $bittersweet;
      color: $white;
    }
  }
}

.ant-modal-footer {
  .ant-btn-primary {
    // @extend .btn-primary;
  }
}

.ant-form-item-has-error .ant-input,
.ant-form-item-has-error .ant-input-affix-wrapper,
.ant-form-item-has-error .ant-input:hover,
.ant-form-item-has-error .ant-input-affix-wrapper:hover {
  background-color: #fff !important;
  border-color: #ff4d4f !important;
}

.ant-input-affix-wrapper,
.ant-picker {
  padding: 0;
  border: solid 1px $alto;
  border-radius: $input-border-radius + px;
  display: flex;

  &:focus,
  &:hover,
  &.ant-input-affix-wrapper-focused,
  &.ant-picker-focused {
    border-color: $primary;
    -webkit-box-shadow: 0 0 0 2px lighten($niagara, 35%);
    box-shadow: 0 0 0 2px lighten($niagara, 35%);
  }

  .ant-input-suffix {
    margin-left: -27px;
    z-index: 12;
  }

  .ant-input-prefix {
    margin-left: em(15);
    font-size: rem(17);
  }

  .ant-picker-suffix {
    margin-right: em(15);
    color: $manatee;
    font-size: rem(17);
  }

  .ant-picker-clear {
    right: em(36);
  }

  input,
  input[type='text'],
  input[type='password'],
  .base-input {
    border-radius: $input-border-radius + px;
    line-height: rem(42);
    background-color: $white;
    border: none;
    color: $mine-shaft;
    font-size: em(16);
    height: rem(40);
    padding: 0 rem(18);
    width: 100%;

    &::-webkit-input-placeholder {
      /* Edge */
      font-size: em(14);
      color: $manatee;
    }

    &:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      font-size: em(14);
      color: $manatee;
    }

    &::placeholder {
      font-size: em(14);
      color: $manatee;
    }
  }
}

.ant-btn.ant-dropdown-trigger {
  @extend .ant-input;

  span:first-child {
    width: 95%;
    text-align: left;
  }
}

.ant-select.ant-select-single {
  width: 100% !important;
  color: $scorpion;

  font-size: rem(14);

  .ant-select-selection-item,
  .ant-select-selection-search,
  .ant-select-selection-placeholder {
    line-height: rem(42);
  }

  .ant-select-selection-placeholder {
    font-size: em(14);
    color: $manatee;
  }

  &.ant-select-focused,
  &:hover {
    border-color: $primary !important;
    -webkit-box-shadow: 0 0 0 2px lighten($niagara, 35%) !important;
    box-shadow: 0 0 0 2px lighten($niagara, 35%) !important;
  }

  .ant-select-item-option-selected {
    background: $primary;
    color: $white;
  }

  div.ant-select-selector {
    border-radius: em($base-border-radius);
    border: solid 1px $clr-input-border !important;
    background-color: $clr-input-bg;
    height: rem(40);
    padding: 0 rem(18);
    width: 100%;

    &:focus,
    &:active {
      border-color: $primary !important;
      -webkit-box-shadow: 0 0 0 2px lighten($niagara, 35%) !important;
      box-shadow: 0 0 0 2px lighten($niagara, 35%) !important;
    }

    &:hover {
      border-color: $primary !important;
    }
  }

  .ant-select-arrow {
    color: $manatee;
  }
}

.ant-radio-group {
  padding: 0;
  display: flex;
}

.ant-radio-group-large .ant-radio-button-wrapper {
  font-size: rem(13);
  height: rem(42);
  line-height: rem(42);
  border-color: $alto !important;

  flex-grow: 1;
  text-align: center;

  &:first-child {
    border-radius: em($input-border-radius) 0 0 em($input-border-radius);
  }

  &:last-child {
    border-radius: 0 em($input-border-radius) em($input-border-radius) 0;
  }

  &:hover {
    color: $primary;
  }
}

.ant-radio-group-solid
  .ant-radio-button-wrapper-checked:not(
    .ant-radio-button-wrapper-disabled
  ):hover {
  background: $primary;
  border-color: $primary;
}

.ant-radio-group-solid
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  background: $primary;
  border-color: $primary;
}

.ant-radio-button-wrapper-checked:not(
    .ant-radio-button-wrapper-disabled
  )::before {
  background-color: $primary;
}

.ant-switch-checked {
  background-color: $primary;
}

// pagination

.ant-pagination-item,
.ant-pagination-next {
  &:hover {
    border-color: $primary;
    color: $primary;
  }
}

.ant-pagination-item-active {
  border-color: $primary;

  a {
    background-color: $primary;
    color: $white;

    &:hover {
      // border-color: $primary;
      color: $white;
    }
  }
}

.ant-spin {
  color: $primary;
}

// Typo
.ant-typography {
  color: $scorpion;
}

// Menu

.ant-menu-vertical {
  .ant-menu-item-selected {
    &::after {
      transform: scaleY(1);
      opacity: 1;
      // right: 9px;
    }
  }
}

.ant-tooltip {
  font-size: em(13);

  &.ant-tooltip-hidden {
    .ant-tooltip-content {
      .menu-item-font-icon {
        &::before {
        }
      }
    }
  }
}

.ant-tooltip {
  .ant-tooltip-content {
    .menu-item-font-icon {
      &::before {
        color: $white;
      }
    }
  }
}

// ant-menu-inline-collapsed-tooltip ant-tooltip-placement-right  ant-tooltip-hidden

// Uploader
.ant-upload-list-item-info {
  color: $scorpion;
}

// Badges
.ant-badge-status-dot {
  width: em(8);
  height: em(8);
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background: #84c0c2;
}

// Error
.ant-form-item-explain-error {
  font-size: em(14);
  margin-top: em(3);
}

// modal Setting
.ant-modal-content {
  border-radius: rem(3);

  .ant-modal-close {
    top: rem(9);
    color: $manatee;
  }

  .ant-modal-footer {
    border: 0;
    text-align: center;
    padding-bottom: rem(24);
    padding-top: 0;
  }
}

.ant-modal-body {
  padding: rem(26) rem(29);

  h4 {
    font-size: rem(18);
  }
}
