[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'app_icons' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-dark-mode:before {
  content: '\e976';
}
.icon-light-mood:before {
  content: '\e977';
  color: #fff;
}
.icon-actual-spend:before {
  content: '\e96f';
  color: #9d50fe;
}
.icon-add-new:before {
  content: '\e90f';
  color: #fff;
}
.icon-all-supplies:before {
  content: '\e970';
  color: #8f8f8f;
}
.icon-arrow-square-left:before {
  content: '\e911';
  color: #fff;
}
.icon-arrow-square-right:before {
  content: '\e910';
  color: #fff;
}
.icon-arrow-up:before {
  content: '\e915';
  color: #fff;
}
.icon-block:before {
  content: '\e912';
  color: #fff;
}
.icon-block1:before {
  content: '\e968';
  color: #fff;
}
.icon-building-columns:before {
  content: '\e975';
  color: #fe50d8;
}
.icon-calendar:before {
  content: '\e90a';
  color: #888997;
}
.icon-camera:before {
  content: '\e913';
  color: #fff;
}
.icon-cancel:before {
  content: '\e914';
  color: #fff;
}
.icon-check-2:before {
  content: '\e969';
  color: #888997;
}
.icon-committed-spend:before {
  content: '\e971';
  color: #fe5050;
}
.icon-contract:before {
  content: '\e919';
  color: #fff;
}
.icon-cross:before {
  content: '\e908';
  color: #888997;
}
.icon-dashboard:before {
  content: '\e900';
  color: #888997;
}
.icon-download:before {
  content: '\e96d';
  color: $niagara;
}
.icon-edit:before {
  content: '\e90c';
  color: $niagara;
}
.icon-expand:before {
  content: '\e916';
}
.icon-file:before {
  content: '\e909';
  color: #888997;
}
.icon-file-2:before {
  content: '\e917';
  color: #fff;
}
.icon-file-invoice-dollar:before {
  content: '\e902';
  color: #888997;
}
.icon-files-1:before {
  content: '\e918';
  color: #fff;
}
.icon-filter:before {
  content: '\e96e';
  color: $niagara;
}
.icon-hide-password:before {
  content: '\e907';
  color: #5a5a5a;
}
.icon-icon-contract:before {
  content: '\e903';
  color: $niagara;
}
.icon-invoices:before {
  content: '\e972';
  color: #22d792;
}
.icon-log-out:before {
  content: '\e905';
  color: #888997;
}
.icon-maintenance .path1:before {
  content: '\e927';
  color: rgb(19, 194, 194);
}
.icon-maintenance .path2:before {
  content: '\e928';
  margin-left: -1em;
  color: rgb(8, 151, 156);
}
.icon-maintenance .path3:before {
  content: '\e929';
  margin-left: -1em;
  color: rgb(248, 118, 80);
}
.icon-maintenance .path4:before {
  content: '\e92a';
  margin-left: -1em;
  color: rgb(10, 134, 140);
}
.icon-maintenance .path5:before {
  content: '\e92b';
  margin-left: -1em;
  color: rgb(8, 151, 156);
}
.icon-maintenance .path6:before {
  content: '\e92c';
  margin-left: -1em;
  color: rgb(7, 122, 126);
}
.icon-maintenance .path7:before {
  content: '\e92d';
  margin-left: -1em;
  color: rgb(106, 223, 223);
}
.icon-maintenance .path8:before {
  content: '\e92e';
  margin-left: -1em;
  color: rgb(19, 194, 194);
}
.icon-maintenance .path9:before {
  content: '\e92f';
  margin-left: -1em;
  color: rgb(10, 134, 140);
}
.icon-maintenance .path10:before {
  content: '\e930';
  margin-left: -1em;
  color: rgb(252, 141, 109);
}
.icon-maintenance .path11:before {
  content: '\e931';
  margin-left: -1em;
  color: rgb(10, 134, 140);
}
.icon-maintenance .path12:before {
  content: '\e932';
  margin-left: -1em;
  color: rgb(252, 141, 109);
}
.icon-maintenance .path13:before {
  content: '\e933';
  margin-left: -1em;
  color: rgb(106, 223, 223);
}
.icon-maintenance .path14:before {
  content: '\e934';
  margin-left: -1em;
  color: rgb(19, 194, 194);
}
.icon-maintenance .path15:before {
  content: '\e935';
  margin-left: -1em;
  color: rgb(10, 134, 140);
}
.icon-maintenance .path16:before {
  content: '\e936';
  margin-left: -1em;
  color: rgb(252, 141, 109);
}
.icon-maintenance .path17:before {
  content: '\e937';
  margin-left: -1em;
  color: rgb(248, 118, 80);
}
.icon-maintenance .path18:before {
  content: '\e938';
  margin-left: -1em;
  color: rgb(255, 148, 117);
}
.icon-maintenance .path19:before {
  content: '\e939';
  margin-left: -1em;
  color: rgb(248, 118, 80);
}
.icon-maintenance .path20:before {
  content: '\e93a';
  margin-left: -1em;
  color: rgb(169, 219, 245);
}
.icon-maintenance .path21:before {
  content: '\e93b';
  margin-left: -1em;
  color: rgb(243, 243, 243);
}
.icon-maintenance .path22:before {
  content: '\e93c';
  margin-left: -1em;
  color: rgb(228, 228, 228);
}
.icon-medical-records:before {
  content: '\e901';
  color: #888997;
}
.icon-minus:before {
  content: '\e90d';
  color: #ff6b6b;
}
.icon-pdf:before {
  content: '\e96c';
  color: $niagara;
}
.icon-pending-process:before {
  content: '\e96a';
  color: #fff;
}
.icon-pie-chart:before {
  content: '\e96b';
  color: $niagara;
}
.icon-purchase-order:before {
  content: '\e965';
  color: $niagara;
}
.icon-purchase-order1:before {
  content: '\e973';
  color: #fea31b;
}
.icon-row-view:before {
  content: '\e966';
  color: #888997;
}
.icon-search:before {
  content: '\e90b';
  color: #888997;
}

.icon-search-white:before {
  content: '\e90b';
  color: #fff;
}
.icon-select-tick:before {
  content: '\e964';
  color: #fff;
}
.icon-send:before {
  content: '\e90e';
  color: #fff;
}
.icon-setting:before {
  content: '\e904';
  color: #888997;
}
.icon-shape:before {
  content: '\e963';
}
.icon-software .path1:before {
  content: '\e91a';
  color: rgb(8, 151, 156);
}
.icon-software .path2:before {
  content: '\e91b';
  margin-left: -1em;
  color: rgb(19, 194, 194);
}
.icon-software .path3:before {
  content: '\e91c';
  margin-left: -1em;
  color: rgb(245, 113, 79);
}
.icon-software .path4:before {
  content: '\e91d';
  margin-left: -1em;
  color: rgb(255, 196, 49);
}
.icon-software .path5:before {
  content: '\e91e';
  margin-left: -1em;
  color: rgb(8, 209, 117);
}
.icon-software .path6:before {
  content: '\e91f';
  margin-left: -1em;
  color: rgb(19, 194, 194);
}
.icon-software .path7:before {
  content: '\e920';
  margin-left: -1em;
  color: rgb(106, 223, 223);
}
.icon-software .path8:before {
  content: '\e921';
  margin-left: -1em;
  color: rgb(19, 194, 194);
}
.icon-software .path9:before {
  content: '\e922';
  margin-left: -1em;
  color: rgb(252, 141, 109);
}
.icon-software .path10:before {
  content: '\e923';
  margin-left: -1em;
  color: rgb(225, 95, 57);
}
.icon-software .path11:before {
  content: '\e924';
  margin-left: -1em;
  color: rgb(204, 241, 241);
}
.icon-software .path12:before {
  content: '\e925';
  margin-left: -1em;
  color: rgb(194, 232, 229);
}
.icon-software .path13:before {
  content: '\e926';
  margin-left: -1em;
  color: rgb(155, 203, 203);
}
.icon-stock .path1:before {
  content: '\e93d';
  color: rgb(10, 134, 140);
}
.icon-stock .path2:before {
  content: '\e93e';
  margin-left: -0.8203125em;
  color: rgb(10, 134, 140);
}
.icon-stock .path3:before {
  content: '\e93f';
  margin-left: -0.8203125em;
  color: rgb(255, 255, 255);
}
.icon-stock .path4:before {
  content: '\e940';
  margin-left: -0.8203125em;
  color: rgb(243, 240, 255);
}
.icon-stock .path5:before {
  content: '\e941';
  margin-left: -0.8203125em;
  color: rgb(255, 135, 101);
}
.icon-stock .path6:before {
  content: '\e942';
  margin-left: -0.8203125em;
  color: rgb(248, 118, 80);
}
.icon-stock .path7:before {
  content: '\e943';
  margin-left: -0.8203125em;
  color: rgb(255, 135, 101);
}
.icon-stock .path8:before {
  content: '\e944';
  margin-left: -0.8203125em;
  color: rgb(248, 118, 80);
}
.icon-stock .path9:before {
  content: '\e945';
  margin-left: -0.8203125em;
  color: rgb(222, 241, 242);
}
.icon-stock .path10:before {
  content: '\e946';
  margin-left: -0.8203125em;
  color: rgb(19, 194, 194);
}
.icon-stock .path11:before {
  content: '\e947';
  margin-left: -0.8203125em;
  color: rgb(222, 241, 242);
}
.icon-stock .path12:before {
  content: '\e948';
  margin-left: -0.8203125em;
  color: rgb(19, 194, 194);
}
.icon-stock .path13:before {
  content: '\e949';
  margin-left: -0.8203125em;
  color: rgb(222, 241, 242);
}
.icon-stock .path14:before {
  content: '\e94a';
  margin-left: -0.8203125em;
  color: rgb(19, 194, 194);
}
.icon-stock .path15:before {
  content: '\e94b';
  margin-left: -0.8203125em;
  color: rgb(222, 241, 242);
}
.icon-stock .path16:before {
  content: '\e94c';
  margin-left: -0.8203125em;
  color: rgb(19, 194, 194);
}
.icon-stock .path17:before {
  content: '\e94d';
  margin-left: -0.8203125em;
  color: rgb(222, 241, 242);
}
.icon-stock .path18:before {
  content: '\e94e';
  margin-left: -0.8203125em;
  color: rgb(19, 194, 194);
}
.icon-stock .path19:before {
  content: '\e94f';
  margin-left: -0.8203125em;
  color: rgb(234, 104, 66);
}
.icon-stock .path20:before {
  content: '\e950';
  margin-left: -0.8203125em;
  color: rgb(19, 194, 194);
}
.icon-stock .path21:before {
  content: '\e951';
  margin-left: -0.8203125em;
  color: rgb(39, 176, 180);
}
.icon-stock .path22:before {
  content: '\e952';
  margin-left: -0.8203125em;
  color: rgb(255, 135, 101);
}
.icon-stock .path23:before {
  content: '\e953';
  margin-left: -0.8203125em;
  color: rgb(248, 118, 80);
}
.icon-stock .path24:before {
  content: '\e954';
  margin-left: -0.8203125em;
  color: rgb(255, 255, 255);
}
.icon-stock .path25:before {
  content: '\e955';
  margin-left: -0.8203125em;
  color: rgb(222, 241, 242);
}
.icon-stock .path26:before {
  content: '\e956';
  margin-left: -0.8203125em;
  color: rgb(243, 117, 81);
}
.icon-stock .path27:before {
  content: '\e957';
  margin-left: -0.8203125em;
  color: rgb(254, 100, 111);
}
.icon-stock .path28:before {
  content: '\e958';
  margin-left: -0.8203125em;
  color: rgb(253, 71, 85);
}
.icon-stock .path29:before {
  content: '\e959';
  margin-left: -0.8203125em;
  color: rgb(255, 255, 255);
}
.icon-stock .path30:before {
  content: '\e95a';
  margin-left: -0.8203125em;
  color: rgb(222, 241, 242);
}
.icon-stock .path31:before {
  content: '\e95b';
  margin-left: -0.8203125em;
  color: rgb(255, 135, 101);
}
.icon-stock .path32:before {
  content: '\e95c';
  margin-left: -0.8203125em;
  color: rgb(248, 118, 80);
}
.icon-stock .path33:before {
  content: '\e95d';
  margin-left: -0.8203125em;
  color: rgb(255, 255, 255);
}
.icon-stock .path34:before {
  content: '\e95e';
  margin-left: -0.8203125em;
  color: rgb(222, 241, 242);
}
.icon-tick:before {
  content: '\e95f';
  color: #fff;
}
.icon-total-contracts:before {
  content: '\e974';
  color: #13c2c2;
}
.icon-unlock-security:before {
  content: '\e967';
  color: #888997;
}
.icon-upload:before {
  content: '\e960';
  color: #474b4b;
}
.icon-upload-contract:before {
  content: '\e961';
  color: $niagara;
}
.icon-user:before {
  content: '\e906';
  color: #5a5a5a;
}
.icon-warning:before {
  content: '\e962';
  color: #f87b57;
}
