@import '../../assets/scss/variables/_variable.scss';

.ant-layout-header {
  padding: 0 em(30) 0 em(30);
}

.app-header {
  //background: $body-bg;
  background: linear-gradient(106deg, #1f8efa 0%, #04c884 100%);
  height: rem(72);
  border-bottom: 1px solid #e6f1e6;

  .ant-row {
    height: rem(72);
  }

  .ant-select-show-search {
    max-width: em(260);
  }
}

.app-main-logo {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  .side-logo {
    margin: 0;
    padding: 0;
  }
}

.app-login-user {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  .ant-avatar {
    width: rem(38);
  }

  .label-login-user-name {
    font-size: rem(15);
    font-weight: 500;
    padding: 0;
    margin: 0 em(24) 0 em(6);
    line-height: rem(22);
    a {
      color: $white;
    }
  }

  .btn-logout {
    font-size: rem(20);
    cursor: pointer;
    color: $white;
    &:hover {
      &::before {
        color: $primary;
      }
    }
  }
}

.org-name {
  margin: 0 12px 0 0;
  font-weight: 700;
}
